/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as SettingsIcon } from '@material-design-icons/svg/round/more_horiz.svg'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  deleteCancelReportUser,
  followRequest,
  postReportUser,
  unFollowRequest,
} from 'api'

import Container from 'components/common/Container'
import Loader from 'components/common/Loader'
import SettingIcon from 'components/icons/SettingIcon'
import ConfirmModal from 'components/modals/ConfirmModal'
import PostsContent from 'components/PostsContent'
import VerifiedIcon from 'components/VerifiedIcon'
import { setLogInModalOpen } from 'store/reducers/modalReducer'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import useFetchRequestProfile from 'hooks/query/useFetchRequestProfile'
import useInfiniteFetchProfilePosts from 'hooks/query/useInfiniteFetchProfilePosts'
import { useAppSelector } from 'hooks/store'
import useModal from 'hooks/useModal'
import MobileHeader from '../../components/MobileHeader'

import c from './ProfilePage.module.scss'

import 'swiper/css'
import 'swiper/css/effect-cards'
import 'swiper/css/pagination'

function ProfilePage() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { nickname } = useParams()

  const { pathname } = useLocation()

  const { data: myProfileData, isLoading: profileIsLoading } =
    useFetchRequestMyProfile()

  const isMyProfile = useMemo(
    () => nickname === myProfileData?.nickname,
    [nickname, myProfileData?.nickname],
  )

  const { data: userData, refetch } = useFetchRequestProfile({ user: nickname })

  const queryClient = useQueryClient()

  const { ref, inView } = useInView()

  const [adminPanel, setAdminPanel] = useState<boolean>(false)
  const [isConfirmModal, setIsConfirmModal] = useState(false)

  const mutationReport = useMutation<void, Error>(() => {
    return postReportUser(userData?.id!)
  })
  const mutationUnReport = useMutation<void, Error>(() => {
    return deleteCancelReportUser(userData?.id!)
  })

  const reportUser = useCallback(() => {
    try {
      if (userData?.is_strike) {
        mutationUnReport.mutateAsync().then(() => {
          queryClient.invalidateQueries({
            queryKey: ['profile', nickname],
          })
          refetch()
          toast.success(t('general.post.successCancelReport'))
        })
      } else {
        mutationReport.mutateAsync().then(() => {
          queryClient.invalidateQueries({
            queryKey: ['profile', nickname],
          })
          refetch()
          toast.success(t('general.post.successReport'))
        })
      }
      setIsConfirmModal(false)
    } catch (error) {
      console.log(error)
      toast.error(t('error.somethingWrong'))
    }
  }, [mutationReport, setIsConfirmModal])

  const { logInModalOpen } = useAppSelector((state) => state.modal)
  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)

  const {
    data: infinitePostsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchProfilePosts({ id: userData?.id, take: 8 })

  const postsData = useMemo(() => {
    if (!infinitePostsData) return []
    return infinitePostsData.pages.flatMap((p) => p)
  }, [infinitePostsData])

  useEffect(() => {
    if (isLoading) return
    fetchNextPage()
  }, [inView])

  const [isFollowed, setIsFollowed] = useState(userData?.is_subscribed)

  const mutationFollow = useMutation<void, Error>(() => {
    return followRequest(userData?.id!)
  })

  const mutationUnFollow = useMutation<void, Error>(() => {
    return unFollowRequest(userData?.id!)
  })

  const handleOpenChat = useCallback(() => {
    navigate(`/chats/${userData?.id}`)
  }, [userData])

  useEffect(
    () => setIsFollowed(userData?.is_subscribed),
    [userData?.is_subscribed],
  )
  const followHandler = () => {
    if (isFollowed) {
      setIsFollowed(false)
      mutationUnFollow.mutate()
    } else {
      setIsFollowed(true)
      mutationFollow.mutate()
    }
  }

  const activePage = (page: string) => {
    const array = pathname.split('/')
    const name = array[array.length - 1]
    return page === name
  }

  const isAdmin = myProfileData?.role === 'admin'

  return (
    <div className={c.profilePage}>
      <MobileHeader
        heading={userData?.nickname}
        isOnGoBack
        isUserPremium={userData?.is_paid_subscription}
      />
      <ConfirmModal
        confirmFunc={reportUser}
        cancelFunc={() => setIsConfirmModal(false)}
        heading={t('profile.reportModalText')}
        isModalOpen={isConfirmModal}
      />
      <Container fluid>
        <div className={c.content}>
          <div className={c.profile}>
            <div className={c.information}>
              <div className={c.avatarWrapper}>
                {userData?.avatar && (
                  <img className={c.avatar} src={userData?.avatar} alt="" />
                )}
              </div>
              <div className={c.user}>
                <div className={c.nicknameWrapper}>
                  <p className={c.nickname}>
                    {userData?.surname && <span>{userData?.surname}</span>}
                    {userData?.name && (
                      <span className={c.verifiedName}>
                        {userData?.name}
                        {userData.is_paid_subscription && <VerifiedIcon />}
                      </span>
                    )}
                    {!userData?.name && !userData?.surname && (
                      <span>{userData?.nickname}</span>
                    )}
                  </p>
                  {userData?.description && (
                    <p className={c.description}>{userData.description}</p>
                  )}
                  <div className={c.wrapperSettings}>
                    {isMyProfile && (
                      <NavLink
                        to="/settings"
                        end
                        className={`${c.settingContainer} ${c.settingsLink}`}
                      >
                        <div className={c.settingContent}>
                          <SettingIcon className={c.settingsIcon} />
                          {t('settings.heading')}
                        </div>
                      </NavLink>
                    )}
                  </div>
                </div>
                {!isMyProfile && !profileIsLoading && (
                  <div className={c.profileActions}>
                    {isAdmin && (
                      <button
                        type="button"
                        className={`${c.follow} ${
                          isFollowed ? c.followed : ''
                        }`}
                        onClick={
                          !myProfileData ? openLogInModal : handleOpenChat
                        }
                      >
                        {t('aiChat.messageInput')}
                      </button>
                    )}
                    <button
                      type="button"
                      className={`${c.follow} ${isFollowed ? c.followed : ''}`}
                      onClick={!myProfileData ? openLogInModal : followHandler}
                    >
                      {mutationFollow.isLoading ? (
                        t('general.loading')
                      ) : (
                        <>
                          {isFollowed && t('profile.unFollow')}
                          {!isFollowed && t('profile.follow')}
                        </>
                      )}
                    </button>
                    <div className={c.adminContainer}>
                      <button
                        type="button"
                        onClick={() => setAdminPanel(!adminPanel)}
                        className={c.admin}
                      >
                        <SettingsIcon
                          fill="#D1D1D6"
                          className={c.settingsIcon}
                        />
                      </button>
                      {adminPanel && (
                        <div className={c.adminPanel}>
                          {userData?.is_strike ? (
                            <button
                              type="button"
                              className={c.reportAction}
                              onClick={() => setIsConfirmModal(true)}
                            >
                              {t('general.buttons.unReport')}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className={c.reportAction}
                              onClick={() => setIsConfirmModal(true)}
                            >
                              {t('general.buttons.report')}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={c.rightPart}>
              <div className={c.statistics}>
                <div className={c.statistic}>
                  <div className={c.value}>
                    {userData?.subscriptions_count || 0}
                  </div>
                  <div className={c.title}>{t('profile.following')}</div>
                  {isMyProfile && (
                    <NavLink to="/subscriptions" end className={c.link} />
                  )}
                </div>
                <div className={c.statistic}>
                  <div className={c.value}>
                    {userData?.subscribers_count || 0}
                  </div>
                  <div className={c.title}>{t('profile.followers')}</div>
                  {isMyProfile && (
                    <NavLink to="/subscribers" end className={c.link} />
                  )}
                </div>
                <div className={c.statistic}>
                  <div className={c.value}>{userData?.likes_count || 0}</div>
                  <div className={c.title}>{t('profile.likes')}</div>
                </div>
              </div>
              {!isMyProfile && (
                <div className={c.rightPartActions}>
                  <button
                    type="button"
                    className={`${c.follow} ${isFollowed ? c.followed : ''} ${
                      c.followMobile
                    }`}
                    onClick={followHandler}
                  >
                    {mutationFollow.isLoading ? (
                      t('general.loading')
                    ) : (
                      <>
                        {isFollowed && t('profile.unFollow')}
                        {!isFollowed && t('profile.follow')}
                      </>
                    )}
                  </button>
                  <div className={c.adminContainer}>
                    <button
                      type="button"
                      onClick={() => setAdminPanel(!adminPanel)}
                      className={c.admin}
                    >
                      <SettingsIcon fill="#D1D1D6" className={c.settingsIcon} />
                    </button>
                    {adminPanel && (
                      <div className={c.adminPanel}>
                        {userData?.is_strike ? (
                          <button
                            type="button"
                            className={c.reportAction}
                            onClick={() => setIsConfirmModal(true)}
                          >
                            {t('general.buttons.unReport')}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className={c.reportAction}
                            onClick={() => setIsConfirmModal(true)}
                          >
                            {t('general.buttons.report')}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  {isAdmin && (
                    <button
                      type="button"
                      className={`${c.follow} ${c.message} ${
                        isFollowed ? c.followed : ''
                      }`}
                      onClick={!myProfileData ? openLogInModal : handleOpenChat}
                    >
                      {t('aiChat.messageInput')}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          {isMyProfile && (
            <div className={c.tabsWrapper}>
              <div className={c.tabsMain}>
                <div className={c.tabContainer}>
                  <Link
                    className={`${c.tabLink} ${
                      activePage(nickname || '') ? c.activeTab : ''
                    }`}
                    to={`/profile/${nickname}`}
                  >
                    {t('profile.myPosts')}
                  </Link>
                </div>
                <div className={c.tabContainer}>
                  <Link
                    className={`${c.tabLink} ${
                      activePage('liked') ? c.activeTab : ''
                    }`}
                    to={`/profile/${nickname}/liked`}
                  >
                    {t('profile.likedPosts')}
                  </Link>
                </div>
                <div className={c.tabContainer}>
                  <Link
                    className={`${c.tabLink} ${
                      activePage('archive') ? c.activeTab : ''
                    }`}
                    to={`/profile/${nickname}/archive`}
                  >
                    {t('profile.archive')}
                  </Link>
                </div>
                <div className={c.tabContainer}>
                  <Link
                    className={`${c.tabLink} ${
                      activePage('albums') ? c.activeTab : ''
                    }`}
                    to={`/profile/${nickname}/albums`}
                  >
                    {t('profile.concepts')}
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activePage(nickname || '') && (
            <div className={c.columnsContainer}>
              {postsData.map((postData) => {
                return <PostsContent key={postData.id} post={postData} />
              })}
            </div>
          )}
          {!isLoading && !isFetchingNextPage && hasNextPage && (
            <div ref={ref} />
          )}
          {isFetchingNextPage && (
            <div className={c.loaderWrapper}>
              <div className={c.loader}>
                <Loader />
              </div>
            </div>
          )}
        </div>
        <Outlet />
      </Container>
    </div>
  )
}

export default ProfilePage
