/* eslint-disable no-else-return */
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { useMutation } from '@tanstack/react-query'
import { readNotification } from 'api'
import classNames from 'classnames'
import isThisMonth from 'date-fns/isThisMonth'
import isToday from 'date-fns/isToday'
import { groupBy } from 'lodash'

import Loader from 'components/common/Loader'
import Notification from 'components/Notification'
import { setNotificationModalOpen } from 'store/reducers/modalReducer'
import useInfiniteFetchNotifications from 'hooks/query/useInfiniteFetchNotifications'
import { useAppSelector } from 'hooks/store'
import useModal from 'hooks/useModal'
import useResize from 'hooks/useResize'
import { Notification as NotificationType } from 'types/Notifications'
import CloseIcon from '../../icons/CloseIcon'
import MobileHeader from '../../MobileHeader'

import s from './NotificationsModal.module.scss'

function NotificationsModal() {
  const { notificationModalOpen } = useAppSelector((state) => state.modal)
  const { close: closeNotificationModal } = useModal(
    notificationModalOpen,
    setNotificationModalOpen,
  )
  const widthWindow = useResize()

  const { t } = useTranslation()
  const {
    data: notificationsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchNotifications()

  const { ref, inView } = useInView()

  const notifications = useMemo(() => {
    if (notificationsData === undefined) return { items: [], meta: {} }

    const combinedItems = notificationsData.pages.flatMap((p) => p.items)
    const lastMeta =
      notificationsData.pages[notificationsData.pages.length - 1].meta

    return {
      items: combinedItems,
      meta: lastMeta,
    }
  }, [notificationsData])

  const mutationReadNotifications = useMutation<void, Error, number[]>(
    (unreadNotificationsIds) => {
      return readNotification(unreadNotificationsIds)
    },
  )

  useEffect(() => {
    if (notifications.items.length > 0) {
      const unreadNotificationsIds = notifications.items
        .filter((n: NotificationType) => !n.is_viewed)
        .map((n: NotificationType) => n.id)

      mutationReadNotifications.mutate(unreadNotificationsIds)
    }
  }, [notifications])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (widthWindow <= 930) {
      const originalStyle = window.getComputedStyle(document.body).overflow
      const originalOverflowX = window.getComputedStyle(document.body).overflowX

      document.body.style.overflow = 'hidden'
      document.body.style.overflowX = 'hidden'

      return () => {
        document.body.style.overflow = originalStyle
        document.body.style.overflowX = originalOverflowX
      }
    }
  }, [])

  const groupNotifications = (notifs: NotificationType[]) => {
    return groupBy(notifs, (n) => {
      const createdAt = new Date(n.created_at)

      if (isToday(createdAt)) {
        return t('notifications.today')
      } else if (isThisMonth(createdAt)) {
        return t('notifications.month')
      } else {
        return t('notifications.previously')
      }
    })
  }

  // eslint-disable-next-line consistent-return
  const groupedNotifications = useMemo(() => {
    if (notifications) {
      return groupNotifications(notifications.items)
    }
  }, [notifications])

  useEffect(() => {
    if (isLoading) return
    fetchNextPage()
  }, [inView])

  return (
    <>
      <div
        className={classNames({
          [s.overlay]: !notificationModalOpen,
          [s.overlayActive]: notificationModalOpen,
        })}
      />
      <div className={s.wrapper}>
        <div className={s.container}>
          <MobileHeader
            heading={t('notifications.title')}
            onClose={closeNotificationModal}
          />
          <div className={s.headerModal}>
            <div className={s.heading}>{t('notifications.title')}</div>
            <button
              type="button"
              onClick={closeNotificationModal}
              className={s.closeButton}
              aria-label="close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className={s.notifications}>
            {Object.entries(groupedNotifications!).map(([group, items]) => (
              <ul className={s.notifications__list} key={group}>
                <h3 className={s.group}>{group}</h3>
                {items.map((n) => (
                  <Notification key={n.id} notification={n} />
                ))}
              </ul>
            ))}
            {!isLoading && !isFetchingNextPage && hasNextPage && (
              <div ref={ref} />
            )}
            {isFetchingNextPage && (
              <div className={s.loaderWrapper}>
                <div className={s.loader}>
                  <Loader />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default NotificationsModal
