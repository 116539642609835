import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/filled/filter_none.svg'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addToFavorites, postPost } from 'api'
import classNames from 'classnames'

import LightBox from 'components/LightBox'
import { changeStage } from 'store/reducers/stageReducer'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppDispatch } from 'hooks/store'
import useCopy from 'hooks/useCopy'
import useSave from 'hooks/useSave'
import { BaseAsset, Status } from 'types/Response'
import Loader from '../common/Loader'
import FinishResultCard from '../FinishResultCard'

import c from './DallE3Finish.module.scss'

interface GenerationFinishProps {
  currentAssetIndex: number
  text?: string
  assets?: BaseAsset[]
  onGoBack: () => void
  sharedSlides: number[]
  onGenerateMore: () => void
  isLoading: boolean
  setSharedSlides: React.Dispatch<React.SetStateAction<number[]>>
  onTextChange: (newText: string) => void
  generationStatus: Status
}

function DallE3Finish({
  currentAssetIndex,
  onGoBack,
  isLoading,
  assets = [],
  text = '',
  onGenerateMore,
  onTextChange,
  sharedSlides,
  setSharedSlides,
  generationStatus,
}: GenerationFinishProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()

  const dispatch = useAppDispatch()

  const [currentSlide, setCurrentSlide] = useState<number>(currentAssetIndex)
  const [isFavorited, setIsFavorited] = useState<number[]>([])
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState<{
    [key: number]: boolean
  }>(assets.reduce((acc, asset) => ({ ...acc, [asset.id]: false }), {}))
  const [lightboxIndex, setLightboxIndex] = useState(0)

  const mutationAddToFavorites = useMutation<void, Error>(() => {
    return addToFavorites(assets?.[currentSlide]?.id)
  })

  const handleAddToFavorites = () => {
    if (isFavorited.includes(currentSlide)) return

    mutationAddToFavorites
      .mutateAsync()
      .then(() => setIsFavorited((prev) => [...prev, currentSlide]))
  }

  const variationsHandler = (assetId: number) => {
    dispatch(changeStage('idle'))
    navigate(`/magic-variations/${assetId}`)
  }

  const handleImageLoaded = useCallback((assetId: number) => {
    setIsImageLoaded((prevState) => ({ ...prevState, [assetId]: true }))
  }, [])

  const handleFullScreenOpen = (index: number) => {
    setLightboxIndex(index)
    setIsLightboxOpen(true)
  }

  const handleFullScreenClose = () => {
    setIsLightboxOpen(false)
  }

  useEffect(() => {
    const mainContent = document.getElementById('generationContainerMain')
    if (mainContent) {
      if (isLightboxOpen) {
        mainContent.style.zIndex = '100'
      } else {
        mainContent.style.zIndex = '1'
      }
    }
  }, [isLightboxOpen])

  const renderAssets = useMemo(() => {
    return assets.map((asset, index) => (
      <FinishResultCard
        key={asset.id}
        asset={asset}
        index={index}
        isImageLoaded={isImageLoaded}
        handleImageLoaded={handleImageLoaded}
        handleFullScreenOpen={handleFullScreenOpen}
        variationsHandler={variationsHandler}
      />
    ))
  }, [assets, isImageLoaded, handleFullScreenOpen, variationsHandler])

  return (
    <div
      className={classNames({
        [c.finishingStage]: !isLoading,
        [c.loadingStage]: isLoading,
      })}
    >
      {isLoading && assets ? (
        <div className={c.loader}>
          <Loader />
        </div>
      ) : (
        <div className={c.content}>
          {generationStatus !== 'pending' && renderAssets}
          {isLightboxOpen && assets?.[lightboxIndex]?.url && (
            <LightBox
              imageUrl={assets[lightboxIndex].url || 'defaultImg'}
              onCloseRequest={handleFullScreenClose}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default DallE3Finish
