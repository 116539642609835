import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { changeStage } from 'store/reducers/stageReducer'
import { useAppDispatch } from 'hooks/store'
import { BaseAsset, Status } from 'types/Response'
import Loader from '../../../common/Loader'
import FinishResultCard from '../../../FinishResultCard'

import c from './FinishStage.module.scss'

interface FinishStageProps {
  text?: string
  currentAssetUrl?: string | undefined
  currentAssetIndex: number
  assets?: BaseAsset[]
  sharedSlides: number[]
  setSharedSlides: React.Dispatch<React.SetStateAction<number[]>>
  onGenerateMore: () => void
  onGoBack: () => void
  isLoading: boolean
  onTextChange: (newText: string) => void
  generationStatus: Status
}

function FinishStage({
  text = '',
  isLoading = false,
  currentAssetUrl = undefined,
  currentAssetIndex,
  assets = [],
  sharedSlides,
  setSharedSlides,
  onGenerateMore,
  onGoBack,
  onTextChange,
  generationStatus,
}: FinishStageProps) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [assetsSuccess, setAssetsSuccess] = useState<BaseAsset[]>([])
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState<{
    [key: number]: boolean
  }>(assets.reduce((acc, asset) => ({ ...acc, [asset.id]: false }), {}))
  const [lightboxIndex, setLightboxIndex] = useState(0)

  useEffect(() => {
    assets.forEach((elem, index) => {
      if (
        elem.status === 'success' &&
        !assetsSuccess.find((el) => el?.id === elem?.id)
      ) {
        setAssetsSuccess((prev) => {
          const newArr = [...prev]
          newArr[index] = elem
          return newArr
        })
      }
    })
  }, [assets])

  const [firstProcessedAssetIndex, setFirstProcessedAssetIndex] = useState<
    number | undefined
  >(undefined)
  const [currentSlide, setCurrentSlide] = useState<number>(currentAssetIndex)

  const variationsHandler = () => {
    dispatch(changeStage('idle'))
    navigate(`/magic-variations/${assets?.[currentSlide]?.id}`)
  }

  useEffect(() => {
    if (firstProcessedAssetIndex) return
    const processedAsset = assets?.findIndex((a) => a.url)
    if (processedAsset) {
      setFirstProcessedAssetIndex(processedAsset)
    }
  }, [assets])

  const isThisSlideShared = useMemo(
    () => sharedSlides.includes(currentSlide),
    [sharedSlides, currentSlide],
  )

  const onRepeat = () => {
    setAssetsSuccess([])
    onGenerateMore()
  }

  const handleImageLoaded = useCallback((assetId: number) => {
    setIsImageLoaded((prevState) => ({ ...prevState, [assetId]: true }))
  }, [])

  const handleFullScreenOpen = (index: number) => {
    setLightboxIndex(index)
    setIsLightboxOpen(true)
  }

  const renderAssets = useMemo(() => {
    return assets.map((asset, index) => (
      <FinishResultCard
        key={asset.id || `asset-${index}`}
        asset={asset}
        index={index}
        isVideo
        isImageLoaded={isImageLoaded}
        handleImageLoaded={handleImageLoaded}
        handleFullScreenOpen={handleFullScreenOpen}
        variationsHandler={variationsHandler}
      />
    ))
  }, [assets, isImageLoaded, handleFullScreenOpen, variationsHandler])

  return (
    <div
      className={classNames({
        [c.finishingStage]: !isLoading,
        [c.loadingStage]: isLoading,
      })}
    >
      {isLoading && assets ? (
        <div className={c.loader}>
          <Loader />
        </div>
      ) : (
        <div className={c.content}>
          {generationStatus !== 'pending' && renderAssets}
        </div>
      )}
    </div>
  )
}

export default FinishStage
