import React from 'react'

import Container from 'components/common/Container'
import Suber from 'components/Suber'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import useFetchSubscriptions from 'hooks/query/useFetchSubscriptions'

import s from './SubscriptionsPage.module.scss'

function SubscriptionsPage() {
  const { data: myProfileData } = useFetchRequestMyProfile()

  const { data: subs } = useFetchSubscriptions({ userId: myProfileData?.id! })

  return (
    <Container flex>
      <div className={s.subs}>
        <ul className={s.subs__list}>
          {subs &&
            subs.length > 0 &&
            subs.map((sub) => (
              <Suber sub={sub} key={sub.id} type="subscriptions" />
            ))}
        </ul>
      </div>
    </Container>
  )
}

export default SubscriptionsPage
