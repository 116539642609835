import React from 'react'

import NotificationEnd from 'components/NotificationEnd'
import NotificationMiddle from 'components/NotificationMiddle'
import NotificationStart from 'components/NotificationStart'
import { Notification as NotificationType } from 'types/Notifications'

import s from './Notification.module.scss'

interface NotificationProps {
  notification: NotificationType
}

function Notification({ notification }: NotificationProps) {
  return (
    <li className={s.notifications__item}>
      <NotificationStart notification={notification} />
      <NotificationMiddle notification={notification} />
      <NotificationEnd notification={notification} />
    </li>
  )
}

export default Notification
