import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import Button from 'components/common/Button'
import Loader from 'components/common/Loader'
import AiChatIcon from 'components/icons/AiChatIcon'
import LanguageChanger from 'components/LanguageChanger'
import NotificationsModal from 'components/modals/NotificationsModal'
import NotificationBell from 'components/NotificationBell'
import VerifiedIcon from 'components/VerifiedIcon'
import {
  setLogInModalOpen,
  setNotificationModalOpen,
  setPaymentModalOpen,
  setSignUpModalOpen,
} from 'store/reducers/modalReducer'
import useLogOut from 'hooks/query/auth/useLogOut'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppSelector } from 'hooks/store'
import useLocationInfo from 'hooks/useLocationInfo'
import useModal from 'hooks/useModal'
import Search from '../../../components/Search'
import logoG from '../../../images/logoG.png'

import c from './LayoutHeader.module.scss'

function DesktopLayoutHeader() {
  const { t } = useTranslation()

  const {
    logInModalOpen,
    signUpModalOpen,
    notificationModalOpen,
    paymentModalOpen,
  } = useAppSelector((state) => state.modal)
  const modalRef = useRef<HTMLDivElement>(null)

  const { id } = useParams()
  const location = useLocation()

  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)
  const { open: openSignUpModal } = useModal(
    signUpModalOpen,
    setSignUpModalOpen,
  )
  const { open: openPaymentModal } = useModal(
    paymentModalOpen,
    setPaymentModalOpen,
  )
  const { open: openNotificationModal, close: closeNotificationModal } =
    useModal(notificationModalOpen, setNotificationModalOpen)

  const toggleNotificationModal = useCallback(() => {
    if (notificationModalOpen) {
      closeNotificationModal()
      return
    }

    openNotificationModal()
  }, [notificationModalOpen])

  const isActive = useCallback(
    (path: string) => location.pathname === path,
    [location.pathname],
  )

  const { isLoading: isUserLoading, data: user } = useFetchRequestMyProfile()

  const { pathname } = useLocationInfo()

  const { error } = useLogOut()

  const handleClickOutside = useCallback(
    (event: any) => {
      const target = event.target as HTMLElement
      if (modalRef.current && !modalRef.current.contains(target)) {
        closeNotificationModal()
      }
    },
    [closeNotificationModal],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.addEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(() => {
    if (error?.message) toast.error(error.message)
  }, [error?.message])

  const headerContent = useMemo(() => {
    return (
      <div className={c.content}>
        <div className={c.leftPart}>
          <div className={c.logo}>
            <img className={c.logoImage} alt="Logo" src={logoG} />
            <p className={c.logoText}>Magicly</p>
            <NavLink className={c.link} to="/" end />
          </div>
          {!user?.is_paid_subscription && (
            <button
              type="button"
              className={c.glareButton}
              onClick={user ? openPaymentModal : openLogInModal}
            >
              <VerifiedIcon style={{ width: '24px', height: '24px' }} />
              <div className={c.updateToPremium}>
                {t('profile.updateTo')}
                <span className={c.textPremium}>Premium</span>
              </div>
            </button>
          )}
        </div>
        <div className={c.rightPart}>
          <div className={c.headerInstruments}>
            <NavLink
              to="/chats"
              className={`${c.navItem} ${c.chats} ${
                isActive(`/chats`) || isActive(`/chats/${id}`)
                  ? c.navItemActive
                  : ''
              }`}
              end
            >
              <AiChatIcon />
            </NavLink>
            <NavLink
              to="/search"
              className={`${c.navItem} ${
                isActive(`/search`) ? c.navItemActive : ''
              }`}
              end
            >
              <Search />
            </NavLink>
            {user && (
              /* eslint-disable jsx-a11y/click-events-have-key-events */
              <div
                role="button"
                tabIndex={0}
                onClick={toggleNotificationModal}
                className={classNames({
                  [c.notificationsBell]: true,
                  [c.activeNotificationBell]: notificationModalOpen,
                })}
                ref={modalRef}
              >
                <NotificationBell />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={(event) => event.stopPropagation()}
                  className={classNames({
                    [c.notificationss]: notificationModalOpen,
                  })}
                >
                  {notificationModalOpen && <NotificationsModal />}
                </div>
              </div>
            )}
          </div>

          {!isUserLoading && (
            <>
              {user && (
                <div className={c.user}>
                  <div className={c.avatarWrapper}>
                    {user.avatar && (
                      <img
                        className={c.avatar}
                        src={user.avatar}
                        alt="avatar"
                      />
                    )}
                    <p className={c.avatarCover} />
                  </div>
                  <p className={c.nickname}>{user.nickname}</p>
                  {user.is_paid_subscription && <VerifiedIcon />}
                  <NavLink
                    className={c.link}
                    to={`/profile/${user.nickname}`}
                    end
                  />
                </div>
              )}

              {!user && (
                <div>
                  <Button
                    className={c.authButton}
                    variant="text"
                    onClick={openLogInModal}
                  >
                    {t('general.auth.logIn')}
                  </Button>
                </div>
              )}
            </>
          )}
          {isUserLoading && (
            <div className={c.loader}>
              <Loader />
            </div>
          )}
          <LanguageChanger />
        </div>
      </div>
    )
  }, [
    t,
    user,
    pathname,
    isUserLoading,
    openLogInModal,
    openSignUpModal,
    notificationModalOpen,
  ])

  return (
    <header className={c.header}>
      <div className={c.container}>{headerContent}</div>
    </header>
  )
}

export default DesktopLayoutHeader
