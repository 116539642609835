import React, { useEffect, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import classNames from 'classnames'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

import GenerateHelp from 'components/GenerateHelp'
import CheckBackStatus from 'components/helpers/CheckBackStatus'
import ScrollToTop from 'components/helpers/ScrollToTop'
import Toastify from 'components/helpers/Toastify'
import LogInModal from 'components/modals/LogInModal'
import PaymentModal from 'components/modals/PaymentModal'
import SignUpModal from 'components/modals/SignUpModal'
import SwitchLanguageModal from 'components/modals/SwitchLanguageModal'
import UserModal from 'components/modals/UserModal'
import {
  setLogInModalOpen,
  setPaymentModalOpen,
  setSignUpModalOpen,
  setSwitchLanguageModalOpen,
  setUpdateUserModalOpen,
  setUserModalOpen,
} from 'store/reducers/modalReducer'
import { clearExpired } from 'store/reducers/viewedReducer'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useModal from 'hooks/useModal'
import CalcFuncEmpty from '../../components/CalcFuncEmpty/CalcFuncEmpty'
import Container from '../../components/common/Container'
import NotificationsModal from '../../components/modals/NotificationsModal'
import UpdateUserModal from '../../components/modals/UpdateUserModal/UpdateUserModal'
import TrendingNavItems from '../../components/TrendingNavItems'
import logoG from '../../images/logoG.png'
import LayoutBottom from './LayoutBottom'
import LayoutHeader from './LayoutHeader'

import c from './MainLayout.module.scss'

function MainLayout() {
  const dispatch = useAppDispatch()
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const {
    logInModalOpen,
    // promoModalOpen,
    signUpModalOpen,
    userModalOpen,
    updateUserModalOpen,
    notificationModalOpen,
    paymentModalOpen,
    switchLanguageModalOpen,
  } = useAppSelector((state) => state.modal)

  TimeAgo.addLocale(en)
  TimeAgo.addLocale(ru)

  const { close: closeLogInModal } = useModal(logInModalOpen, setLogInModalOpen)

  const { close: closePaymentModal } = useModal(
    paymentModalOpen,
    setPaymentModalOpen,
  )

  const { close: closeSignUpModal } = useModal(
    signUpModalOpen,
    setSignUpModalOpen,
  )

  const { open: openUserModal, close: closeUserModal } = useModal(
    userModalOpen,
    setUserModalOpen,
  )

  const { close: closeUpdateUserModal } = useModal(
    updateUserModalOpen,
    setUpdateUserModalOpen,
  )

  const { data: userData } = useFetchRequestMyProfile()

  useEffect(() => {
    if (userData && !userData?.nickname) {
      openUserModal()
    }
  }, [userData])

  useEffect(() => {
    window.localStorage.setItem('device-id', deviceId)
  }, [deviceId])

  useEffect(() => {
    dispatch(clearExpired())
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const storageLang = window.localStorage.getItem('languageModal')
    if (storageLang === 'true') {
      dispatch(setSwitchLanguageModalOpen(true))
    }
  }, [])

  return (
    <div className={c.layout}>
      <CheckBackStatus />
      <ScrollToTop />
      <Container>
        <div className={c.content} id="main">
          <div className={c.leftPart} id="aside">
            <div className={c.logo}>
              <img className={c.logoImage} alt="Logo" src={logoG} />
              <p className={c.logoText}>Magicly</p>
              <NavLink className={c.link} to="/" end />
            </div>
            <TrendingNavItems />
          </div>
          <div className={c.rightPart}>
            {!isMobile && <LayoutHeader />}
            <div className={c.rightPartContent} id="content">
              <Outlet />
            </div>
          </div>
          <GenerateHelp />
        </div>
      </Container>
      <LayoutBottom />
      {/* <PromoModal open={promoModalOpen} onClose={closePromoModal} /> */}
      <div
        className={classNames({
          [c.notificationss]: notificationModalOpen,
        })}
      >
        {notificationModalOpen && <NotificationsModal />}
      </div>
      <PaymentModal open={paymentModalOpen} closeModal={closePaymentModal} />
      {switchLanguageModalOpen ? (
        <SwitchLanguageModal isOpen={switchLanguageModalOpen} />
      ) : (
        <>
          <LogInModal open={logInModalOpen} onClose={closeLogInModal} />
          {userData?.id === undefined && (
            <SignUpModal open={signUpModalOpen} onClose={closeSignUpModal} />
          )}
        </>
      )}
      <UserModal open={userModalOpen} onClose={closeUserModal} />
      <UpdateUserModal
        open={updateUserModalOpen}
        onClose={closeUpdateUserModal}
      />
      <Toastify />
      <CalcFuncEmpty />
    </div>
  )
}

export default MainLayout
