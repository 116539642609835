import React from 'react'

import Container from 'components/common/Container'
import Suber from 'components/Suber'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import useFetchSubscribers from 'hooks/query/useFetchSubscribers'

import s from './SubscribersPage.module.scss'

function SubscribersPage() {
  const { data: myProfileData } = useFetchRequestMyProfile()

  const { data: subs } = useFetchSubscribers({
    userId: myProfileData?.id!,
  })

  return (
    <Container flex>
      <div className={s.subs}>
        <ul className={s.subs__list}>
          {subs &&
            subs.length > 0 &&
            subs.map((sub) => (
              <Suber sub={sub} key={sub.id} type="subscribers" />
            ))}
        </ul>
      </div>
    </Container>
  )
}

export default SubscribersPage
