import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { postPost } from 'api'

import Button from 'components/common/Button'
import GenerationContainer, {
  AsideSlot,
  MainSlot,
} from 'components/GenerationControls/GenerationContainer/GenerationContainer'
import ImageFilterCard from 'components/ImageFilterCard'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppSelector } from 'hooks/store'

import c from './PublishPage.module.scss'

function PublishPage() {
  const stage = useAppSelector((state) => state.stage.stage)
  const [isChecked, setIsChecked] = useState(false)
  const { t } = useTranslation()
  const { assetId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: profile } = useFetchRequestMyProfile()

  const mutationShare = useMutation<void, Error, number>((id) => {
    return postPost({ assets: [id!], subscribers_only: isChecked })
  })

  const shareHandler = (id: number) => {
    mutationShare
      .mutateAsync(id)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [`infinite-profilePosts-${profile?.id}`],
        })
      })
      .then(() => {
        toast.success(t('publishPost'))
      })
      .catch((error) => {
        console.log(error)
        toast.error(t('error.somethingWrong'))
      })
  }

  return (
    <GenerationContainer stage={stage}>
      <MainSlot>
        <ImageFilterCard />
      </MainSlot>
      <AsideSlot>
        <div className={c.wrapper}>
          <div className={c.publishWrapper}>
            <span className={c.title}>{t('publish.title')}</span>
            <label className={c.subscribers}>
              <input
                type="checkbox"
                name=""
                id=""
                className={c.input}
                onChange={(event) => {
                  setIsChecked(event.target.checked)
                }}
              />{' '}
              {t('publish.isSubscribers')}
            </label>
            <Button
              className={c.button}
              onClick={() => {
                shareHandler(+assetId!)
                navigate(`/profile/${profile?.nickname}`)
              }}
            >
              {t('general.buttons.publish')}
            </Button>
          </div>
        </div>
      </AsideSlot>
    </GenerationContainer>
  )
}

export default PublishPage
