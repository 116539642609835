import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'

import bell from 'components/icons/bell.svg'
import {
  setLogInModalOpen,
  setNotificationModalOpen,
  setPaymentModalOpen,
} from 'store/reducers/modalReducer'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppSelector } from 'hooks/store'
import useModal from 'hooks/useModal'
import Button from '../common/Button'
import ArrowLeft from '../icons/ArrowLeft'
import CloseIcon from '../icons/CloseIcon'
import PremiumIcon from '../icons/PremiumIcon'
import SearchIcon from '../icons/SearchIcon'
import SettingIcon from '../icons/SettingIcon'

import c from './MobileHeader.module.scss'

interface MobileHeaderProps {
  isOnGoBack?: boolean
  onClose?: () => void
  isUserPremium?: boolean
  heading: string | undefined
}

export default function MobileHeader({
  isOnGoBack = false,
  isUserPremium = false,
  onClose = undefined,
  heading,
}: MobileHeaderProps) {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [empty, setEmpty] = useState(false)
  const location = useLocation()

  const { id } = useParams()

  const { data: myProfileData } = useFetchRequestMyProfile()

  const { notificationModalOpen, paymentModalOpen, logInModalOpen } =
    useAppSelector((state) => state.modal)

  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)
  const { open: openPaymentModal } = useModal(
    paymentModalOpen,
    setPaymentModalOpen,
  )

  const { open: openNotificationModal, close: closeNotificationModal } =
    useModal(notificationModalOpen, setNotificationModalOpen)

  const toggleNotificationModal = useCallback(() => {
    if (notificationModalOpen) {
      closeNotificationModal()
      return
    }

    openNotificationModal()
  }, [notificationModalOpen])

  const navigate = useNavigate()
  const { nickname } = useParams()

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 640)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const truncateHeading = useCallback((text: string | undefined) => {
    if (text && text.length > 32) {
      return `${text.substring(0, 32)}...`
    }
    return text
  }, [])

  const isActive = useCallback(
    (path: string) => location.pathname === path,
    [location.pathname],
  )

  const onGoBack = useCallback(() => {
    if (navigate(-1) === undefined) {
      setEmpty(true)
      return
    }

    navigate(-1)
  }, [navigate])

  if (!isMobile) return null
  return (
    <div className={c.header}>
      <div className={c.leftPart}>
        <button type="button" onClick={isOnGoBack ? onGoBack : onClose}>
          {isOnGoBack && !empty && <ArrowLeft />}
          {!isOnGoBack && !empty && <CloseIcon style={{ width: '12px' }} />}
        </button>
      </div>
      <span className={c.heading}>
        {truncateHeading(heading)}
        {isUserPremium && (
          <PremiumIcon style={{ width: '18px', height: '18px' }} />
        )}
      </span>
      <div className={c.rightPart}>
        <button
          type="button"
          className={`${c.iconContainer}`}
          onClick={toggleNotificationModal}
        >
          <img src={bell} alt="" className={c.icon} />
        </button>
        <NavLink
          to="/search"
          end
          className={`${c.iconContainer}`}
          onClick={closeNotificationModal}
        >
          <SearchIcon className={c.icon} />
        </NavLink>
        {myProfileData && !myProfileData.is_paid_subscription && (
          <Button
            variant="outlined"
            onClick={myProfileData ? openPaymentModal : openLogInModal}
          >
            <span className={c.proText}>PRO</span>
          </Button>
        )}
        {(isActive(`/profile/${nickname}`) ||
          isActive(`/profile/${nickname}/liked`) ||
          isActive(`/profile/${nickname}/archive`) ||
          isActive(`/profile/${nickname}/albums`)) && (
          <NavLink
            to="/settings"
            end
            className={`${c.settingContainer} ${c.settingsLink}`}
          >
            <SettingIcon className={c.settingsIcon} />
          </NavLink>
        )}
      </div>
    </div>
  )
}
