import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Img } from 'react-image'
import { useParams } from 'react-router-dom'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'

import IconButton from 'components/common/IconButton'
import Loader from 'components/common/Loader'
import CopyIcon from 'components/icons/CopyIcon'
import useFetchAsset from 'hooks/query/useFetchAsset'
import useCopy from 'hooks/useCopy'

import c from './ImageFilterCard.module.scss'

function ImageFilterCard() {
  const { t } = useTranslation()

  const imageRef = useRef<HTMLImageElement>(null)

  const { assetId } = useParams()

  const { data, isLoading } = useFetchAsset({ assetId })

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const { isCopied, handleCopy } = useCopy(data?.source || '')

  const toggleText = useCallback(() => {
    setIsExpanded((prev) => !prev)
  }, [])

  const truncateText = (text: string) => {
    return text.length > 100 ? `${text.substring(0, 100)}... ` : text
  }

  const handleImageLoaded = useCallback(() => {
    setIsImageLoaded(true)
  }, [])

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon className={c.icon} style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon />
    )
  }, [isCopied])

  if (isLoading) {
    return (
      <div className={c.loaderWrapper}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={c.wrapper}>
      <div className={c.imageWrapper}>
        {data?.url && (
          <Img
            src={data?.url}
            className={c.image}
            loader={<Loader />}
            onLoad={handleImageLoaded}
            ref={imageRef}
          />
        )}
      </div>
      <div className={c.cardBottom}>
        {data && data.source_type !== 'image' && (
          <div className={c.textWrapper}>
            <div className={c.text}>
              <span>
                {isExpanded ? data.source : truncateText(data.source)}
              </span>
              {data.source.length > 100 && (
                <button
                  type="button"
                  className={c.buttonTextContainer}
                  onClick={toggleText}
                >
                  {isExpanded ? (
                    <span className={c.buttonTextHide}>
                      {t('general.post.hideText')}
                    </span>
                  ) : (
                    <span className={c.buttonTextShow}>
                      {t('general.post.showText')}
                    </span>
                  )}
                </button>
              )}
            </div>
            <IconButton
              className={c.postCopy}
              disabled={isCopied}
              onClick={handleCopy}
            >
              {copyIcon}
            </IconButton>
          </div>
        )}
        {data && data.style && data.style !== 'undefined' && (
          <div className={c.cardTags}>
            <div className={c.textStyleContainer}>
              <span className={c.textStyle}>{data.style}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ImageFilterCard
