import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { followRequest, unFollowRequest } from 'api'

import { setNotificationModalOpen } from 'store/reducers/modalReducer'
import { useAppDispatch } from 'hooks/store'
import { Notification as NotificationType } from 'types/Notifications'

import s from './NotificationEnd.module.scss'

interface NotificationEndProps {
  notification: NotificationType
}

function NotificationEnd({ notification }: NotificationEndProps) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [isSubscribed, setIsSubscribed] = useState(
    notification.from_user.is_subscribed,
  )
  const mutationFollow = useMutation<void, Error, string>((id) => {
    return followRequest(id!)
  })
  const mutationUnFollow = useMutation<void, Error, string>((id) => {
    return unFollowRequest(id!)
  })
  const handleFollow = () => {
    mutationFollow.mutate(notification.from_user.id)
    setIsSubscribed(!isSubscribed)
    toast.success('Everything went well')
  }

  const handleUnfollow = () => {
    mutationUnFollow.mutate(notification.from_user.id)
    setIsSubscribed(!isSubscribed)
    toast.success('Everything went well')
  }
  return (
    <div className={s.whatExactly}>
      {notification.type === 'subscription' &&
        (isSubscribed ? (
          <button type="button" onClick={handleUnfollow} className={s.unFollow}>
            {t('profile.unFollow')}
          </button>
        ) : (
          <button type="button" className={s.follow} onClick={handleFollow}>
            {t('profile.follow')}
          </button>
        ))}
      {(notification.type === 'like' ||
        notification.type === 'like_comment' ||
        notification.type === 'reply') && (
        <div className={s.imageWrapper}>
          <img src={notification.post.url} alt="" />
          <video src={notification.post.url} muted className={s.image} />
          <NavLink
            className={s.link}
            end
            to={`/trending-post/${notification.post.id}`}
            onClick={() => dispatch(setNotificationModalOpen(false))}
          />
        </div>
      )}
    </div>
  )
}

export default NotificationEnd
